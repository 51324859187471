body {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px;
}
.custom-control-label,
.noNamesCount,
.form-label {
  font-size: 14px !important;
}
.result-section {
  text-align: center;
}

.draw-block {
  padding-top: 0px;
  padding-bottom: 20px;
}

.draw-section {
  font-size: 32px;
  padding-bottom: 20px;
  /* padding-top: 40px; */
}
.banner {
  text-align: center;
  padding: 30px;
  background-color: #333;
  color: #fff;
}
.banner h2 {
  margin-bottom: 10px;
}
.banner h4 {
  padding: 0;
  margin: 0;
}

.btn {
  width: 400px;
  font-size: 15px !important;
  text-transform: uppercase;
}

.itemBox {
  padding: 10px 24px;
  font-size: 22px;
  background: #f44336;
  color: #fff;
  margin-right: 15px;
  font-weight: 600;
  width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 12px;
    border-radius: 4px;
}

.mx-auto {
  margin: auto;
}
.align-center {
  text-align: center;
}

.textLoop {
  margin-top: 50px;
  min-height: 100px;
}

.header.collapse {
  display: none !important;
}
.footer .container .col-lg-auto {
  width: 100%;
}

.draw-section .card {
  margin: 0px;
}
.draw-section .card-body {
  padding: 25px 16px 30px;
}

.draw-section .card h2 {
  margin-bottom: 10px;
}
.header-brand,
.header-brand-img {
  margin-right: 0px !important;
}
.header-toggler {
  display: none;
}
